:root {
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --neutraldark-gray: rgba(80, 80, 80, 1);
  --text-regular-normal-font-family: "Inter-Regular", Helvetica;
  --text-regular-normal-font-weight: 400;
  --text-regular-normal-font-size: 16px;
  --text-regular-normal-letter-spacing: 0px;
  --text-regular-normal-line-height: 150%;
  --text-regular-normal-font-style: normal;
  --text-tiny-normal-font-family: "Inter-Regular", Helvetica;
  --text-tiny-normal-font-weight: 400;
  --text-tiny-normal-font-size: 12px;
  --text-tiny-normal-letter-spacing: 0px;
  --text-tiny-normal-line-height: 150%;
  --text-tiny-normal-font-style: normal;
  --text-tiny-link-font-family: "Roboto-Regular", Helvetica;
  --text-tiny-link-font-weight: 400;
  --text-tiny-link-font-size: 12px;
  --text-tiny-link-letter-spacing: 0px;
  --text-tiny-link-line-height: 150%;
  --text-tiny-link-font-style: normal;
  --text-small-normal-font-family: "Roboto-Regular", Helvetica;
  --text-small-normal-font-weight: 400;
  --text-small-normal-font-size: 14px;
  --text-small-normal-letter-spacing: 0px;
  --text-small-normal-line-height: 150%;
  --text-small-normal-font-style: normal;
  --text-small-link-font-family: "Roboto-Regular", Helvetica;
  --text-small-link-font-weight: 400;
  --text-small-link-font-size: 14px;
  --text-small-link-letter-spacing: 0px;
  --text-small-link-line-height: 150%;
  --text-small-link-font-style: normal;
  --text-regular-bold-font-family: "Roboto-Bold", Helvetica;
  --text-regular-bold-font-weight: 700;
  --text-regular-bold-font-size: 16px;
  --text-regular-bold-letter-spacing: 0px;
  --text-regular-bold-line-height: 150%;
  --text-regular-bold-font-style: normal;
  --fraunches-heading4-font-family: "Fraunces-Black", Helvetica;
  --fraunches-heading4-font-weight: 900;
  --fraunches-heading4-font-size: 24px;
  --fraunches-heading4-letter-spacing: 0.48px;
  --fraunches-heading4-line-height: 32px;
  --fraunches-heading4-font-style: normal;
  --variable-collection-pink-light: rgba(255, 237, 255, 1);
  --variable-collection-gray-15: rgba(38, 38, 38, 1);
  --variable-collection-gray-50: var(--variable-collection-gray-50);
  --variable-mobile-frames-left: 76%;
}
